button, .btn
    display: inline-block
    text-align: center
    vertical-align: middle
    background-color: #FFFFFF
    color: #020101
    border-radius: 50px
    padding: 15px 20px
    &:hover
        background-color: #020101
        color: #FFFFFF
        border: 1px solid
    @media (max-width: 768px)
        padding: 10px 15px

.btn-lg
    width: clamp(160px, 25vw, 333px)
    height: clamp(160px, 25vw, 333px)
    border-radius: 200px
    display: inline-flex
    justify-content: center
    align-items: center
    border: 1px solid
    &:hover
        background-color: #FFFFFF
        color: #020101
        border: 1px solid
