$font-name: "Mundial"
$font-weight: "Light", "Regular", "Demibold"

$font-secondary-name: "Signifier"
$font-secondary-weight: "LightItalic", "Light"

@if ($font-name != "" and nth($font-weight,1) != "")
    @each $i in $font-weight
        @font-face
            font-family: "#{$font-name nth($i,1)}"
            src: url("../../fonts/#{$font-name}/#{$font-name}-#{nth($i,1)}.otf") format("opentype")

@if ($font-secondary-name != "" and nth($font-secondary-weight,1) != "")
    @each $i in $font-secondary-weight
        @font-face
            font-family: "#{$font-secondary-name nth($i,1)}"
            src: url("../../fonts/#{$font-secondary-name}/#{$font-secondary-name}-#{nth($i,1)}.ttf") format("truetype")

body
    font-family: "Mundial Regular", sans-serif
    font-stretch: normal
    font-style: normal
    font-weight: normal
    letter-spacing: normal
    font-size: 1rem
    line-height: 1.2

h1
    font-family: "Signifier Italic"
    font-size: clamp(46px, 8vw, 120px)
    font-weight: 300
    line-height: 1
    letter-spacing: -0.02em
    text-transform: capitalize
    font-style: italic

    &.display-1
        font-family: "Signifier Light"
        font-size: clamp(96px, 10vw, 200px)

    &.s
        font-style: normal
        font-family: "Mundial Light"

h2
    font-family: "Signifier LightItalic"
    letter-spacing: -0.01em
    line-height: 100%
    font-size: clamp(40px, 6vw, 96px)
    font-weight: 300

    &.s
        font-family: "Mundial Light"
            font-style: italic

h3
    font-family: "Signifier Light"
    font-weight: 300
    font-size: clamp(32px, 5vw, 57px)
    line-height: 110%

h4
    font-family: "Signifier Light"
    font-weight: 300
    font-size: clamp(28px, 4vw, 42px)
    line-height: 110%

h5
    font-family: "Mundial Light"
    font-size: 1.25rem

h6
    font-family: "Mundial Light"
    font-size: 1rem

p
    font-family: "Mundial Regular"
    font-size: 1rem
    font-weight: 400
    line-height: 1.5

    &.body-1
        font-size: clamp(20px, 3vw, 24px)
        letter-spacing: 0.01em
    &.body-2
        font-size: 20px
    &.body-3
        font-size: 1rem

    &.sub-1
        font-size: clamp(18px, 2.5vw, 20px)
        letter-spacing: 0.14em
        line-height: 120%
        text-transform: uppercase

    &.sub-2
        font-size: clamp(14px, 2vw, 16px)
        letter-spacing: 0.14em
        text-transform: uppercase
        line-height: 120%

    &.sub-3
        font-family: "Mundial Demibold"
        font-size: 11px
        line-height: 120%
        letter-spacing: 0.11em
        text-transform: uppercase

a
    text-transform: uppercase
    font-size: clamp(16px, 2.5vw, 20px)
    font-weight: 500
    &.sm
        font-size: 11px

.btn,
button
    font-size: clamp(14px, 2vw, 16px)
    font-weight: 500
