*,
*::before,
*::after
    box-sizing: border-box // Use a more-intuitive box-sizing model.

*
    margin: 0

html,
body,
#root
    height: 100% // Allow percentage-based heights in the application

html
    font-size: 100% // Correct text resizing oddly in IE 6/7 when body `font-size` is set using `em` units.
    -webkit-text-size-adjust: 100% // Prevent iOS text size adjust after orientation change, without disabling user zoom.
    -ms-text-size-adjust: 100%
    scroll-behavior: smooth

body
    line-height: 1.5 // Add accessible line-height
    -webkit-font-smoothing: antialiased // Improve text rendering
    overflow-x: hidden

img,
picture,
video,
canvas,
svg
    display: block
    max-width: 100%

input,
button,
textarea,
select
    font: inherit // Remove built-in form typography styles

p,
h1,
h2,
h3,
h4,
h5,
h6
    overflow-wrap: break-word // Avoid text overflows

#root
    isolation: isolate // Create a root stacking context

a,
a:visited,
button
    cursor: pointer
    text-decoration: none
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out, borde-color 300ms ease-in-out
    &:active,
    &:hover
        transition: color 300ms ease-in-out, background-color 300ms ease-in-out, borde-color 300ms ease-in-out

svg
    *
        transition: stroke 300ms ease-in-out, fill 300ms ease-in-out
    &:hover
        *
            transition: stroke 300ms ease-in-out, fill 300ms ease-in-out

i
    vertical-align: middle
